<template>
    <AppContainer>
        <Appointments/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/UserDashboardContainer.vue'
import Appointments from '@/components/userDashboard/appointments/Appointments.vue'
export default {
  name: 'EditPro',
  components: {
    AppContainer,
    Appointments
  }
}
</script>