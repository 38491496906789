<template>
    <div class="card mb-0 border">
        <div class="card-header">
            <h3 class="card-title text-dark">Appointments</h3>
        </div>
        <div class="card-body p-1">
            <!-- Appointments-table-area  -->
            <div class="leads-table-area addcustomer-table addcampaigm-table settings-tab my-3">
                <ul class="nav nav-tabs nav-justified tabs-menu" role="tablist">
                    <li class="nav-item">
                        <router-link class="nav-link" :class="$route.params.type == 'upcoming' ? 'active':''" :to="{ name: 'userAppointments',params:{type:'upcoming'} }">Upcoming</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" :class="$route.params.type == 'past' ? 'active':''" :to="{ name: 'userAppointments',params:{type:'past'} }">Past</router-link>
                    </li>
                </ul>
                <table id="appt_table" class="display">
                    <thead>
                        <tr>
                            <th class="Adds">ID</th>
                            <!-- <th>Created Date</th> -->
                            <th>Customer</th>
                            <th>Provider</th>
                            <th>Price</th>
                            <th>Duration</th>
                            <th style="width:70px">Status</th>
                            <th>Schedule</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
            <!-- Appointments-table-area  -->
        </div>
    </div>

    <!-- Modal -->
    <div id="container">
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="modal-header">
                        <h4 class="modal-title">Review Business</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <!-- Start main body coding  -->
                        <div class="row">
                            <div class="col-sm-12 col-md-4 ">
                                <div class=" text-center">
                                    <img id="businessImg" src="@/assets/images/business_logo/default.jpg" />
                                </div>
                                <h6 class="fw-normal col-sm-12 text-center mt-3 " id="businessName">-</h6>
                            </div>
                            <div class=" col-md-8">
                                <!-- start row one -->
                                <div class=" row mt-3 ">
                                    <div class="col-sm-6 col ">
                                        <p class="mb-2 ">Service Quality</p>
                                        <div class="text-warning  ">
                                           <star-rating  v-model:rating="review_.overAll"  :rating="$route.query.rating"  :star-size="25" :show-rating="false"></star-rating>
                                             <!-- <ul class="results-rating">
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa :icon="['far','star']" />
                                                    </li>
                                                </ul> -->
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col ">
                                        <p class="mb-2">Customer Service</p>
                                        <div class="text-warning ">
                                           <star-rating v-model:rating="review_.punctuality" :rating="$route.query.rating"  :star-size="25" :show-rating="false"></star-rating>
                                             <!-- <ul class="results-rating">   
                                                    <li class="list-inline-item m-0">   
                                                    <fa icon="star" />   
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa :icon="['far','star']" />
                                                    </li>
                                                </ul> -->                                        </div>
                                    </div>
                                </div>
                                <!-- start row 2  -->
                                <div class="row mt-3">
                                    <div class="col-sm-6 col ">
                                        <p class="mb-2">Cleanliness and Hygiene</p>
                                        <div class="text-warning mb-3">
                                            <star-rating  v-model:rating="review_.values" :rating="$route.query.rating" :star-size="25" :show-rating="false"></star-rating>
                                             <!-- <ul class="results-rating">
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa :icon="['far','star']" />
                                                    </li>
                                                </ul> -->
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col">
                                        <p class="mb-2">Time Management</p>
                                        <div class="text-warning">
                                            <star-rating v-model:rating="review_.services" :rating="$route.query.rating"  :star-size="25" :show-rating="false"></star-rating>
                                                <!-- <ul class="results-rating">
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa icon="star" />
                                                    </li>
                                                    <li class="list-inline-item m-0">
                                                    <fa :icon="['far','star']" />
                                                    </li>
                                                </ul> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- start row 3 -->
                                
                                <div class="border-top">
                                    <p class="mt-3 mb-1 mb-0 p-0  ">Leave a review for a business:</p>
                                    <textarea class="form-control col " style="min-height: 130px" id="message-text" v-model="review_.businessReview"></textarea>    
                                </div>
                                <!-- start row 4 -->
                                <div class=" ">
                                    <p class="mt-3 mb-1 mb-0 p-0 ">Leave a review for <b><span id="emp"></span> </b> (Professional):</p>
                                    <textarea class="form-control col " style="min-height: 130px"   id="message-text" v-model="review_.userReview" placeholder="(Optional)"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer border-0 ">
                            <button type="button" class="btn btn-danger btn-lg" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-success btn-lg" v-on:click="review()">Post Review</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BookingDetailModal :booking_detail="booking_detail" :loading="loading" />
    </div>
</template>
<script>
    import axios from "axios"
    import $ from "jquery"
    import { useToast } from "vue-toastification"
    import 'vue3-carousel/dist/carousel.css'

    import "datatables.net"
    import "datatables.net-responsive"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    import "datatables.net-responsive-dt/css/responsive.dataTables.min.css"
    //import moment from "moment"
    import { getOrderDetails } from "@/components/booking/utils"
    import BookingDetailModal from '@/components/booking/modals/BookingDetailModal';
    import StarRating from 'vue-star-rating'

    export default {
        name: "Appointments",
        setup () {
            const toast = useToast();
            return {toast}
        },
        components: {
            StarRating,
            BookingDetailModal
        },
        data() {
            return {
                booking_detail: {},
                loading: false,
                review_:{
                    businessReview: '',
                    userReview: '',
                    services: 0 
                },
                bookingType:2,
                breakpoints: {
                    575: {
                        itemsToShow: 4,
                        itemsToScroll: 1,
                        snapAlign: 'start',
                    },
                    // 700px and up
                    992: {
                        itemsToShow: 5,
                        itemsToScroll: 1,
                        snapAlign: 'start',
                    },
                    // 1024 and up
                    1200: {
                        itemsToShow: 7,
                        itemsToScroll: 1,
                        snapAlign: 'start',
                    }
                },
            };
        },
        methods: {
            updateBookingStatus(id,status,type='booking'){
                let thiss = this
                let msg = "";
                if (status == 'no-show') {
                    msg = "You don't meet this customer."
                }else if (status == 'start') {
                    msg = "You want to start this booking!"
                }else if(status == 'cancel'){
                    msg = "You want to cancel this booking!"
                }else if(status == 'complete'){
                    msg = "This appointment is successfully completed!"
                }
                
                
                this.$swal({
                    title: 'Are you sure?',
                    text: msg,
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    reverseButtons: false,
                    /*customClass: {
                        confirmButton: 'rem-btn mr-3',
                        cancelButton: 'add-btn'
                    },
                    buttonsStyling: false*/
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('update_booking_status',{booking_id:id,status:status}).then(function (response) {
                            console.log(response.data);
                            if (response.data.status == 'success') {
                                if (type == 'queue') {
                                    thiss.getQueus();
                                }else{
                                    $("#appt_table").dataTable().fnDraw();
                                    thiss.toast.success(response.data.message);
                                }
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                if (error.response.status == 422) {
                                    thiss.toast.error(error.response.data.message);
                                }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                                    thiss.$storage.removeStorageSync("user")
                                    thiss.$storage.removeStorageSync("token")
                                    thiss.$storage.removeStorageSync("business")
                                    thiss.$router.push({ name: 'Home'})
                                }else if (error.response.status == 401) {
                                    thiss.toast.error(error.response.data.message,{position: "top-right"});
                                }
                            }
                        });
                    }
                })

            },
            review(){
               
                    console.log(this.review_)
                    let thiss = this
                    axios.post('give_review',this.review_).then(function (response) {
                        if (response.data.status == 'success') {
                            $("#exampleModal").modal("hide");
                        }else if (response.data.status == 'login') {
                            thiss.$storage.removeStorageSync("user")
                            thiss.$storage.removeStorageSync("token")
                            thiss.$storage.removeStorageSync("business")
                            thiss.$router.push({ name: 'Home'})
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 422) {
                                this.toast.error(error.response.data.message);
                            }else if (error.response.status == 401) {
                                this.toast.error(error.response.data.message,{position: "top-right"});
                            }
                        }
                    });
                
            }
        },
        mounted() {
            let thiss = this;
           
            $("#appt_table").dataTable({
                //order: [[7, "desc"]],
                responsive: true,
                fixedHeader: true,
                processing: true,
                serverSide: true,
                ajax: {
                    url: axios.defaults.baseURL + "dt_appointments/client",
                    type: "POST",
                    data: function (d) {
                        d.duration = thiss.$route.params.type;
                    },
                    headers: {
                        Authorization: axios.defaults.headers.common['Authorization'],
                    },
                },
                drawCallback: function () {
                    //alert( 'Table redrawn '+new Date() );
                },
                columnDefs: [
                    {
                        targets: [3, 4, 5],
                        orderable: false,
                    },
                ],
                //columnDefs: [{ targets: [-1, -3], className: 'dt-body-right' }]
            });

            // $(".past_queues_table").dataTable({
            //     responsive: true,
            //     fixedHeader: true,
            //     processing: false,
            //     serverSide: false,
            // });

            $("tbody").on("click", ".change-status", function (e) {
                e.preventDefault();
                let booking_id = $(this).data("id");
                let status = $(this).data("status");
                thiss.updateBookingStatus(booking_id,status)
            });

            $("tbody").on("click", ".give-review", function (e) {
                e.preventDefault();
                let booking_id = $(this).data("id");
                let emp = $(this).data("emp_name");
                let businessName = $(this).data("business_name");
                //let businessImg = $(this).data("business_img");
                thiss.review_.order_id = booking_id;
                $("#emp").html(emp);
                $("#businessName").html(businessName);
                //$("#businessImg").attr("src",businessImg);
            });
            $("tbody").on("click", ".viewOrderDetail", async function (e) {
                e.preventDefault();
                let booking_id = $(this).data("id");
                if(booking_id){
                    $("#bookingDetailModal").modal("show");
                    thiss.loading = true
                    thiss.booking_detail = await getOrderDetails(booking_id)
                    thiss.loading = false
                }
            });
        },
        watch:{
            "$route.params.type": function () {
                $("#appt_table").dataTable().fnDraw();
            }
        }
    }
</script>

<style type="text/css">
/*--Settings tabes--*/
.settings-tab .tabs-menu{
	margin-bottom:25px;
}
.settings-tab .tabs-menu li{
	width:33.3%;
	display:block;
}
.settings-tab .tabs-menu li a{
	padding: 0.5rem 1rem;
	background: #f2f3f8;
	color:#000;
	display: block;
	text-align: center;
    border: none;
    margin: 0;
	border-right:1px solid rgba(167, 180, 201,.2);
}
.settings-tab .tabs-menu li a.active  {
    background: #6e317a;
    color: #fff;
    border-radius: 2px;
	text-align: center;
    padding: 0.5rem 1rem;
    border: none;
    margin: 0;
}
    /********************************
    leads-table-area
    ***********************************/

    .leads-table-area {
        background: #FFFFFF;
        -webkit-box-shadow: 0px 4px 8px rgba(49, 81, 161, 0.08);
        box-shadow: 0px 4px 8px rgba(49, 81, 161, 0.08);
        border-radius: 10px;
        margin-bottom: 15px;
    }

    .leads-table-area td .manname img {
        width: 30px;
        height: 30px;
    }

    .leads-table-area .display td a.manname {
        font-size: 14px;
        font-weight: 500;
        color: #343945;
    }

    .leads-table-area table.dataTable thead th,
    .leads-table-area table.dataTable thead td {
        padding: 10px 10px;
        border-bottom: 1px solid transparent;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        font-family: 'Muli', sans-serif;
        color: #6D778E;
    }

    .leads-table-area .display td a {
        font-size: 12px;
        color: #3151A1;
        font-weight: 800;
        line-height: 1.5;
        text-decoration: none;
    }

    .dataTables_wrapper {
        padding: 15px 0;
        overflow: hidden;
    }

    .leads-table-area table.dataTable tbody th,
    .leads-table-area table.dataTable tbody td {
        padding: 15px 10px;
        font-size: 14px;
        color: #343945;
        font-weight: 400;
        line-height: 1.5;
    }
    /******************************
    addcustomer-table
    *******************************/

    .addcustomer-table .display td a {
        color: #343945;
        font-weight: 600;
    }

    .addcustomer-table table.dataTable tbody th,
    .addcustomer-table table.dataTable tbody td {
        font-weight: 400;
    }

    .addcustomer-table table.dataTable tbody td.dt-body-right {
        text-align: center;
    }

    /* .addcustomer-table .dataTables_wrapper .dataTables_length {
        display: none;
    }

    .addcustomer-table .dataTables_wrapper .dataTables_filter {
        display: none;
    } */

    .addcustomer-table table.dataTable thead th:last-child {
        background-image: none;
    }

    .addcustomer-table table.dataTable tbody td a.opens {
        display: inline-block;
        color: #3151A1;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        background: #DBE5FF;
        border-radius: 3px;
        text-decoration: underline;
        padding: 4px 10px;
        height: 24px;
        width: auto;
        min-width: 64px;
        text-align: center;
    }

    .addcustomer-table table.dataTable tbody td a.solds {
        display: inline-block;
        color: #19CD9D;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        background: rgba(25, 205, 157, 0.1);
        border-radius: 3px;
        text-decoration: none;
        padding: 4px 10px;
        height: 24px;
        min-width: 64px;
        width: auto;
        text-align: center;
    }

    .addcustomer-table table.dataTable tbody td a.losts {
        display: inline-block;
        color: #EB5757;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        background: rgba(235, 87, 87, 0.1);
        border-radius: 3px;
        text-decoration: none;
        padding: 4px 10px;
        height: 24px;
        min-width: 64px;
        width: auto;
        text-align: center;
    }

    .addcustomer-table table.dataTable tbody td a.Adds {
        display: inline-block;
        color: #3151A1;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        background: #DBE5FF;
        border-radius: 3px;
        padding: 4px 10px;
        min-width: 64px;
        height: 24px;
        width: auto;
        text-align: center;
    }

    .addcustomer-table table.dataTable tbody td a.Adds img {
        margin-right: 0px;
        margin-top: -1px;
    }

    .addcustomer-table table.dataTable tbody td:focus {
        outline: 0;
    }
    .dataTables_wrapper .dataTables_paginate .paginate_button.current,
    .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
        color: #fff!important;
        border: 1px solid #3151A1 !important;
        background: #3151A1 !important;
    }
</style>

<style scoped>
@import "../../../assets/css/dashboard/dataTables.css"
</style>